import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

interface FeedbackFormProps {
    open: boolean;
    onClose: () => void;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ open, onClose }) => {
    const [feedback, setFeedback] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedback(event.target.value);
    };

    const handleSubmitFeedback = async () => {
        if (!feedback) return;

        setIsLoading(true);

        try {
            await axios.post('https://discord.com/api/webhooks/1154871289274716252/HHF5rFKr5gyhxUXx3uRXvtA2FOojvW18twDXIFUW_zVkXKZu4NvQUgWdV4UX4PQ23It_', {
                content: feedback,
            });
            setFeedback("");
            onClose();
        } catch (error) {
            console.error("Error sending feedback:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Feedback</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Your Feedback"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    multiline
                    rows={4}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitFeedback}
                    disabled={isLoading}
                >
                    {isLoading ? "Sending..." : "Send Feedback"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FeedbackForm;
