import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";

interface SingleSelectProps {
    label: string;
    value: string;
    onChange: (event: SelectChangeEvent<string>) => void;
    options: { label: string; value: string }[];
}

const SingleSelect: React.FC<SingleSelectProps> = ({ label, value, onChange, options }) => {
    return (
        <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel htmlFor="outlined-select">{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                input={<OutlinedInput label={label} id="outlined-select" />}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};



export default SingleSelect;
