/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from "react";
import axios from "axios";
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    CircularProgress,
    Container,
    Box,
    Snackbar,
    OutlinedInput,
    FormControlLabel,
    Chip,
    SelectChangeEvent,
    Switch
} from "@mui/material";
import Footer from './components/Footer'
import Banner from "./components/Banner";
import Logo from "./components/Logo";
import SingleSelect from "./components/SingleSelect";
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const API_ENDPOINT = process.env.REACT_APP_FAST_API_ENDPOINT as string;
const blueButtonStyle = {
    backgroundColor: "#007BFF",
    color: "white",
    "&:hover": {
        backgroundColor: "#0056b3",
    },
};
const outlinedInputStyles = css`
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #007BFF !important;
}
.MuiInputLabel-outlined.Mui-focused {
  color: #007BFF !important;
}
`;

const appContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
};


const App: React.FC = () => {
    const [fileType, setFileType] = useState<string>("");
    const [isSitesFocused, setIsSitesFocused] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [siteName, setSiteName] = useState<string[]>([]);
    const [location, setLocation] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isRemote, setIsRemote] = useState(false);
    const [isEasyApply, setIsEasyApply] = useState(false);
    const [countryIndeed, setCountryIndeed] = useState<string>("usa");
    const [resultsWanted, setResultsWanted] = useState<number | null>();
    const [distance, setDistance] = useState<string | null>(null);
    const [jobType, setJobType] = useState<string | null>(null);
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleFileTypeChange = (event: SelectChangeEvent<string>) => {
        setFileType(event.target.value);
    };

    const handleSubmit = () => {
        let newErrors = {};

        if (!fileType)
            newErrors = { ...newErrors, fileType: "Must select a File Type" };
        if (siteName.length === 0)
            newErrors = { ...newErrors, siteName: "Must select at least one Site" };
        if (!location)
            newErrors = { ...newErrors, location: "Location cannot be blank" };
        if (siteName.includes("indeed") && !countryIndeed)
            newErrors = { ...newErrors, countryIndeed: "Must select a country for Indeed" };
        if (siteName.includes("glassdoor") && !countryIndeed)
            newErrors = { ...newErrors, countryIndeed: "Must select a country for Glassdoor" };

        if (distance && isNaN(Number(distance))) {
            newErrors = { ...newErrors, distance: "Distance must be a number" };
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);

            setSnackbarMessage("Please fill in all required fields correctly!");
            setOpenSnackbar(true);
            return;
        }

        const jsonData = {
        site_name: siteName,
        search_term: searchTerm,
            is_remote: isRemote,
        location: location,
        results_wanted: (typeof resultsWanted === "number" && !isNaN(resultsWanted)) ? resultsWanted : 15,
        distance: distance ? Number(distance) : null,
        easy_apply: isEasyApply,
        country_indeed: countryIndeed,
        ...(jobType ? { job_type: jobType } : {}),
        file_type: fileType,
    };

        setIsLoading(true);

        axios
            .post(API_ENDPOINT, jsonData, {
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;

                const fileExtension = fileType === "excel" ? ".xlsx" : ".csv";
                link.setAttribute("download", `jobs_${location}${fileExtension}`);

                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .catch((error) => {
                console.log(API_ENDPOINT)
                console.error("Error calling API:", error);
                setSnackbarMessage("Error calling API. Please try again.");
                setOpenSnackbar(true);
            })
            .finally(() => {
                setIsLoading(false);
            });

        setErrors({});
    };


    return (
        <div style={appContainerStyle} css={outlinedInputStyles}>
            <Container maxWidth="md">
                <Box py={4} display="flex" flexDirection="column" alignItems="center">

                    <Logo />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search Jobs"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        margin="normal"
                    />


                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Location (US only for usejobspy.com)"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        margin="normal"
                    />


                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="multi-select-outlined">Sites</InputLabel>
                        <Select
                            multiple
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value as string[])}
                            onFocus={() => setIsSitesFocused(true)}
                            onBlur={() => setIsSitesFocused(false)}
                            input={
                                <OutlinedInput
                                    label="Sites"
                                    id="multi-select-outlined"
                                    notched={isSitesFocused || siteName.length > 0}
                                />
                            }
                            renderValue={(selected) => (
                                <Box display="flex" flexWrap="wrap">
                                    {(selected as string[]).map((value) => (
                                        <Chip key={value} label={value} style={{ margin: 2 }} />
                                    ))}
                                </Box>
                            )}
                        >
                            {/*<MenuItem value="linkedin">*/}
                            {/*    <Checkbox checked={siteName.includes('linkedin')} />*/}
                            {/*    <ListItemText primary="LinkedIn" />*/}
                            {/*</MenuItem>*/}
                            <MenuItem value="indeed">
                                <Checkbox checked={siteName.includes('indeed')} />
                                <ListItemText primary="Indeed" />
                            </MenuItem>
                                                        <MenuItem value="glassdoor">
                                <Checkbox checked={siteName.includes('glassdoor')} />
                                <ListItemText primary="Glassdoor" />
                            </MenuItem>

                            <MenuItem value="zip_recruiter">
                                <Checkbox checked={siteName.includes('zip_recruiter')} />
                                <ListItemText primary="ZipRecruiter" />
                            </MenuItem>

                        </Select>
                    </FormControl>
                    {/*{siteName.includes("indeed") && (*/}
                    {/*    <SingleSelect*/}
                    {/*        label="Country (Indeed)"*/}
                    {/*        value={countryIndeed}*/}
                    {/*        onChange={(e) => setCountryIndeed(e.target.value as string)}*/}
                    {/*        options={[*/}
                    {/*            "Argentina", "Australia", "Austria", "Bahrain", "Belgium", "Brazil",*/}
                    {/*            "Canada", "Chile", "China", "Colombia", "Costa Rica", "Czech Republic",*/}
                    {/*            "Denmark", "Ecuador", "Egypt", "Finland", "France", "Germany", "Greece",*/}
                    {/*            "Hong Kong", "Hungary", "India", "Indonesia", "Ireland", "Israel", "Italy",*/}
                    {/*            "Japan", "Kuwait", "Luxembourg", "Malaysia", "Mexico", "Morocco", "Netherlands",*/}
                    {/*            "New Zealand", "Nigeria", "Norway", "Oman", "Pakistan", "Panama", "Peru",*/}
                    {/*            "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Saudi Arabia",*/}
                    {/*            "Singapore", "South Africa", "South Korea", "Spain", "Sweden", "Switzerland",*/}
                    {/*            "Taiwan", "Thailand", "Turkey", "Ukraine", "United Arab Emirates", "UK", "USA",*/}
                    {/*            "Uruguay", "Venezuela", "Vietnam"*/}
                    {/*        ].map(country => ({ label: country, value: country.toLowerCase().replace(/\s+/g, '_') }))}*/}
                    {/*    />*/}
                    {/*)}*/}


                      <SingleSelect
                        label="File Type"
                        value={fileType}
                        onChange={handleFileTypeChange}
                        options={[
                            {value: 'excel', label: 'Excel'},
                            {value: 'csv', label: 'CSV'}
                        ]}
                    />

<Box mt={3} width="100%">
                    {/*<details>*/}
                    {/*   <summary style={{cursor: "pointer"}}>Optional Settings</summary>*/}
                    {/*    <Box display="flex" flexDirection="column">*/}
                    {/*        <SingleSelect*/}
                    {/*            label="Job Type"*/}
                    {/*            value={jobType || ""}*/}
                    {/*            onChange={(e) => setJobType(e.target.value as string)}*/}
                    {/*            options={[*/}
                    {/*                { value: 'fulltime', label: 'Full Time' },*/}
                    {/*                { value: 'parttime', label: 'Part Time' },*/}
                    {/*                { value: 'internship', label: 'Internship' },*/}
                    {/*                { value: 'contract', label: 'Contract' }*/}
                    {/*            ]}*/}
                    {/*        />*/}
                    {/*        <Box display="flex" alignItems="center" gap={2}>*/}
                    {/*            <TextField*/}
                    {/*                label="Jobs per Site"*/}
                    {/*                value={resultsWanted || ''}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (e.target.value === '') {*/}
                    {/*                        setResultsWanted(null);*/}
                    {/*                    } else {*/}
                    {/*                        setResultsWanted(Number(e.target.value));*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*                variant="outlined"*/}
                    {/*                fullWidth*/}
                    {/*            />*/}
                    {/*            <TextField*/}
                    {/*                label="Distance (in miles)"*/}
                    {/*                value={distance || ''}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    if (e.target.value === '') {*/}
                    {/*                        setDistance(null);*/}
                    {/*                    } else {*/}
                    {/*                        setDistance(e.target.value);*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*                variant="outlined"*/}
                    {/*                fullWidth*/}
                    {/*            />*/}
                    {/*        </Box>*/}
                    {/*    </Box>*/}
                    {/*</details>*/}
                </Box>

                    <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
                        <Box display="flex" justifyContent="center" marginBottom={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isRemote}
                                        onChange={(e) => setIsRemote(e.target.checked)}
                                        style={{ marginRight: "10px" }}
                                    />
                                }
                                label="Remote only"
                            />
        {/*                    <FormControlLabel*/}
        {/*                        control={*/}
        {/*                            <Switch*/}
        {/*                                checked={isEasyApply}*/}
        {/*                                onChange={(e) => setIsEasyApply(e.target.checked)}*/}
        {/*                                style={{ marginRight: "10px" }}*/}
        {/*                            />*/}
        {/*                        }*/}
        {/*                        label={*/}
        {/*                            <span>*/}
        {/*    <FontAwesomeIcon icon={faLinkedin} style={{color: 'black', fontSize: '16px', marginRight: '5px'}} />*/}
        {/*    Easy Apply*/}
        {/*</span>*/}
        {/*                        }*/}
        {/*                    />*/}

                        </Box>

                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading}
                            style={{
                                marginTop: "20px",
                                ...(isLoading ? {} : blueButtonStyle),
                            }}
                        >
                            {isLoading ? "Loading..." : "Spy"}
                        </Button>{" "}
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{ marginLeft: 10, marginTop: "16px" }}
                            />
                        )}
                    </Box>

                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        open={openSnackbar}
                        onClose={handleCloseSnackbar}
                        message={snackbarMessage}
                        autoHideDuration={6000}
                        action={[
                            <Button
                                color="secondary"
                                size="small"
                                onClick={handleCloseSnackbar}
                                style={{ color: '#4CAF50' }}
                            >
                                CLOSE
                            </Button>,
                        ]}
                    />
                </Box>
            </Container>

            <Footer />
        </div>
    );
};

export default App;
